import React from 'react'
import './Pagination.css'

const Pagination = ({ totalPages, currentPage, handlePageChange }) => {
  const displayPages = 3

  const range = (start, end) => {
    return Array.from({ length: end - start + 1 }, (_, i) => start + i);
  };

  const renderPages = () => {
    const halfDisplayPages = Math.floor(displayPages / 2);
    let startPage = Math.max(1, currentPage - halfDisplayPages);
    let endPage = Math.min(totalPages, startPage + displayPages - 1);

    if (endPage - startPage + 1 < displayPages) {
      startPage = Math.max(1, endPage - displayPages + 1);
    }

    const pagesArray = range(startPage, endPage)
    const pagesList = pagesArray.map((number) => (
      <li key={number} className={currentPage === number ? 'active' : ''}>
        <button onClick={() => handlePageChange(number)}>{number}</button>
      </li>
    ))

    if (startPage > 2) {
      pagesList.unshift(<li key={20}> <button disabled>...</button> </li>)
    }
    if(startPage !== 1){
      pagesList.unshift(<li key={1}> <button onClick={() => handlePageChange(1)}>1</button> </li>)
    }
    if (endPage < (totalPages-1)) {
      pagesList.push(<li key={21}> <button disabled>...</button> </li>)
    }
    if(endPage !== totalPages){
      pagesList.push(<li key={totalPages}> <button onClick={() => handlePageChange(totalPages)}>{totalPages}</button> </li>)
    }

    return pagesList
  }

  return (
    <div>
      <ul className="pagination">
        {renderPages()}
      </ul>
    </div>
  )
}

export default Pagination