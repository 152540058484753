import React from 'react';
import Reflux from 'reflux';
import LoginModal from './LoginModal/LoginModal';
import RegisterModal from './RegisterModal/RegisterModal';
import EmailModal from './EmailModal/EmailModal';
import MonitorModal from './MonitorModal.js/MonitorModal';
import ErrorModal from './ErrorModal.js/ErrorModal';
import ContractModal from './ContractModal/ContractModal';
import DossierModal from './DossierModal/DossierModal';
import FirstLoginInfoModal from './FirstLoginInfoModal/FirstLoginInfoModal';

import ModalsStore from '../../stores/ModalsStore';
import ModalsActions from '../../actions/ModalsActions';

import './Modals.css';
import VideoModal from './VideoModal/VideoModal';
import RaportImage from './RaportImage/RaportImage';
import ChangeUserModal from './ChangeUserModal/ChangeUserModal';
import DosarInstantaModal from './DosarInstantaModal/DosarInstantaModal';
import VerifyApiModal from './VerifyApiModal/VerifyApiModal';
import ConfirmModal from './ConfirmModal/ConfirmModal';
import EmailContent from './EmailContent/EmailContent';
import SendEmailModal from './SendEmailModal/SendEmailModal';
import InsGroupModal from './InsGroupModal/InsGroupModal';
import MonDosareModal from './MonDosareModal/MonDosareModal';

class Modals extends Reflux.Component {
  constructor() {
    super();
    this.store = ModalsStore;
  }

  onModalsClick = () => {
    switch (this.state.modal) {
      case 'register':
      case 'contract':
      case 'changeUser':
      case 'sendEmail':
      case 'insGroup':
      case 'emailContent':
      case 'monDosare':
        break;
      default:
        ModalsActions.hideModal();
        break;
    }
  }

  componentDidMount() {
    var language = localStorage.getItem("Language")
    this.setState({ lang: language })
    if (language === "Ro" || language === null) {
      import('../Languages/language_ro').then(lang => {
        this.setState({ language: lang.default.Language })
      })
    }
    if (language === "En") {
      import('../Languages/language_en').then(lang => {
        this.setState({ language: lang.default.Language })
      })
    }
  }

  render() {
    const { visible, modal, title, message, callback, language, lang, url } = this.state;

    let visibleModal = null;
    switch (modal) {
      case 'login':
        visibleModal = <LoginModal message={message} callback={callback} text={language && language.Modals.Login} url={url} />;
        break;
      case 'register':
        visibleModal = <RegisterModal message={message} callback={callback} text={language && language.Modals.Register} />;
        break;
      case 'email':
        visibleModal = <EmailModal text={language && language.Modals.Email} />;
        break;
      case 'monitor':
        visibleModal = <MonitorModal text={language && language.Modals.Monitor} />;
        break;
      case 'error':
        visibleModal = <ErrorModal title={title} message={message} />;
        break;
      case 'contract':
        visibleModal = <ContractModal message={message} text={language && language.Modals.Contract} />
        break;
      case 'dossier':
        visibleModal = <DossierModal title={title} message={message} language={lang} />
        break;
      case 'firstLogin':
        visibleModal = <FirstLoginInfoModal />
        break;
      case 'video':
        visibleModal = <VideoModal url={message} />
        break;
      case 'raportImg':
        visibleModal = <RaportImage src={message} rap={title} />
        break;
      case 'changeUser':
        visibleModal = <ChangeUserModal message={message} text={language && language.Modals.ChangeUser} />
        break;
      case 'jst':
        visibleModal = <DosarInstantaModal dossier={message} />
        break;
      case 'api':
        visibleModal = <VerifyApiModal tip={message} />
        break;
      case 'confirm':
        visibleModal = <ConfirmModal message={message} onConfirm={callback} />
        break;
      case 'emailContent':
        visibleModal = <EmailContent content={message} />
        break;
      case 'sendEmail':
        visibleModal = <SendEmailModal emailId={message} />
        break;
      case 'insGroup':
        visibleModal = <InsGroupModal groupInfo={message} />
        break;
      case 'monDosare':
        visibleModal = <MonDosareModal userId={message} />
      default:
        break;
    }

    return (
      <div className={`modals modal${visible ? '' : ' hidden'}`} onClick={this.onModalsClick}>
        {visibleModal}
      </div>
    );
  }
}

export default Modals;
