import React from 'react'
import Reflux from 'reflux'
import './MonDosareModal.css'
import ModalsActions from '../../../actions/ModalsActions';
import FloatingLableInput from '../../Reusable/FloatingLabelInput/FloatingLableInput';
import AdminActions from '../../../actions/AdminActions';
import AdminStore from '../../../stores/AdminStore';
import SimpleButton from '../../Reusable/SimpleButton/SimpleButton';
import Pagination from '../../Reusable/Pagination/Pagination';

class MonDosareModal extends Reflux.Component {

  constructor(props) {
    super(props)
    this.store = AdminStore
    this.state = {
      filters: {
        nrDosar: '',
        cfiscal: ''
      },
      dosareU: [],
      pageSize: 10,
      pageIndex: 1,
      totalPages: 0,
    }
  }

  componentDidMount() {
    AdminActions.getUserDosare(this.props.userId)
  }

  componentDidUpdate(prevProps, prevState) {
    const {dosare, pageSize} = this.state
    if (dosare !== prevState.dosare)
    {
      let totalPages = dosare.length && Math.ceil(dosare.length / pageSize)
      let dosareU = dosare.slice(0, pageSize)
      this.setState({ dosareU: dosareU, totalPages: totalPages })
    }
  }

  handleCloseClick = () => {
    ModalsActions.hideModal();
    this.setState({ dosareU: [] })
  }

  handleUpdateFilters = (e) => {
    this.setState(prevState => ({
      filters: {
        ...prevState.filters,
        [e.target.name]: e.target.value
      }
    }))
  }

  handleFilter = () => {
    let { nrDosar, cfiscal } = this.state.filters
    let dosareU = this.state.dosare.filter(x => x.NrDosar.includes(nrDosar) && x.CodFiscal.toString().includes(cfiscal.toString()))
    this.setState({ dosareU: dosareU })
  }

  handlePageChange = (pageNb) => {
    const {pageSize, dosare} = this.state
    let skip = (pageNb - 1) * pageSize
    let dosareU = dosare.slice(skip, skip + pageSize)
    this.setState({pageIndex: pageNb, dosareU: dosareU})
  }

  render() {
    const { filters, dosareU, totalPages, pageIndex, pageSize } = this.state

    return (
      <div className='modal-dialog dosareContentModal'>
        <div className="modal-content dosarContentBody">
          <div className="row closeBtn">
            <button type="button" className="modals-close-button close" onClick={(event) => this.handleCloseClick(event)}>&times;</button>
          </div>
          <div className='dosareFilters'>
            <FloatingLableInput label='Numar Dosar' value={filters.nrDosar} name='nrDosar' placeholder='Numar Dosar' onChange={this.handleUpdateFilters} />
            <FloatingLableInput label='Cod Fiscal' value={filters.cfiscal} name='cfiscal' placeholder='Cod Fiscal' onChange={this.handleUpdateFilters} type='number' />
            <SimpleButton text='Filtreaza' onClick={() => this.handleFilter()} />
          </div>
          <p>Nr dosare monitorizate: {dosareU.length}</p>
          {dosareU.length !== 0 &&
            <div>
              <table>
                <thead>
                  <tr>
                    <th>CodFiscal</th>
                    <th>Denumire</th>
                    <th>NrDosar</th>
                    <th>DataInserare</th>
                  </tr>
                </thead>
                <tbody>
                  {dosareU.map((x, ix) => (
                    <tr key={ix}>
                      <td>{x.CodFiscal}</td>
                      <td>{x.Denumire}</td>
                      <td>{x.NrDosar}</td>
                      <td>{x.DataInserare}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination totalPages={totalPages} currentPage={pageIndex} handlePageChange={this.handlePageChange} />
            </div>}
        </div>
      </div>
    )
  }

}

export default MonDosareModal
